import logo from './logo.svg';
import './App.css';
import './css/main.css';
import './css/bootstrap.min.css';
import Home from './pages/home/Home';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Faq from './pages/faq/Faq';
import Trade from './pages/Trade/Trade';
import Features from './pages/Features/Features';
import Contact from './pages/Contact/Contact';
import Dashboard from './pages/Admin/Dashboard';
import Adminogin from './pages/Admin/login';
import AdminRegister from './pages/Admin/register';
import Profile from './pages/Profile';
import { useState, useEffect, useRef,useMemo } from "react";
import { firebase } from '../src/Firebase/config';
import Article from './pages/article/Article';
import ForgotPassword from './pages/Forgotpassword/forgotpassword';

function App() {



  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/article' element={<Article />} />
        <Route path='/trade' element={<Trade />} />
        <Route path='/features' element={<Features />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/Profile' element={<Profile />} />
        <Route path='/Admin/Dashboard' element={<Dashboard />} />
        <Route path='/Admin/login' element={<Adminogin />} />
        <Route path='/Admin/register' element={<AdminRegister />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
