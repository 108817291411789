import React, { useState, useEffect } from 'react';
import { firebase } from '../Firebase/config';
import { BeatLoader } from 'react-spinners';
import {
  FaTachometerAlt,
  FaUser,
  FaWallet,
  FaUsers,
  FaChartLine,
  FaLifeRing,
  FaUserCircle,
  FaBars,
  FaTimes,
  FaSignOutAlt,
  FaMoneyCheckAlt,
} from 'react-icons/fa';
import 'tailwindcss/tailwind.css';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../component/Dashboard';
import Directincome from '../component/Refferal/Directincome';
import Investmentincome from '../component/Refferal/Investmentincome';
import LevelIncome from '../component/Refferal/Levelincome';
import RetopupIncome from '../component/Refferal/Retopup';
import Walllet from '../component/Wallet';
import Profiles from '../component/Profile';
import Withdraw from '../component/Withdraw';

const Profile = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem('userData');
    return storedData ? JSON.parse(storedData) : null;
  });
  const [usersData, setUsersData] = useState(() => {
    const storedData = localStorage.getItem('usersData');
    return storedData ? JSON.parse(storedData) : [];
  });
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('Dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          try {
            const userRef = firebase.firestore().collection('users').doc(user.uid);

            // Fetch current user's data
            const doc = await userRef.get();
            if (doc.exists) {
              const data = doc.data();
              setUserData(data);
              localStorage.setItem('userData', JSON.stringify(data));
            }

            // Listen for real-time updates for the current user
            const unsubscribeUserSnapshot = userRef.onSnapshot((snapshot) => {
              if (snapshot.exists) {
                const updatedData = snapshot.data();
                setUserData(updatedData);
                localStorage.setItem('userData', JSON.stringify(updatedData));
              }
            });

            // Fetch all users' data
            const usersRef = firebase.firestore().collection('users');
            const usersSnapshot = await usersRef.get();
            const allUsersData = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setUsersData(allUsersData);
            localStorage.setItem('usersData', JSON.stringify(allUsersData));

            // Listen for real-time updates for all users
            const unsubscribeUsersSnapshot = usersRef.onSnapshot((snapshot) => {
              const updatedUsersData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              setUsersData(updatedUsersData);
              localStorage.setItem('usersData', JSON.stringify(updatedUsersData));
            });

            return () => {
              unsubscribeUserSnapshot();
              unsubscribeUsersSnapshot();
            };
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        } else {
          console.log('No user signed in');
          localStorage.removeItem('userData');
          localStorage.removeItem('usersData');
          setUserData(null);
          setUsersData([]);
        }
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchData();
  }, []);

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      localStorage.removeItem('userData');
      localStorage.removeItem('usersData');
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <Dashboard userData={userData} usersData={usersData} />;
      case 'Profile':
        return <Profiles />;
      case 'Sponsor Income':
        return <Directincome />;
      case 'Trade Income':
        return <Investmentincome />;
      case 'Affiliate Income':
        return <LevelIncome />;
      case 'Wallet':
        return <Walllet />;
      case 'Withdraw':
        return <Withdraw />;
      default:
        return <div>Dashboard Content</div>;
    }
  };

  return (
    <div
      className="flex min-h-screen"
      style={{
        backgroundImage: 'url(/IMG_6086.PNG)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <button
        className="absolute top-4 right-4 z-50 p-2 bg-gray-900 text-white rounded-full lg:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </button>

      <div
        className={`fixed inset-y-0 left-0 z-50 w-64 bg-gray-900 text-white flex flex-col transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static lg:w-64`}
      >
        <div className="p-4 text-center text-xl font-bold border-b border-gray-700">
          Dashboard
        </div>
        <nav className="flex flex-col space-y-4 mt-4 p-4">
          {[
            { name: 'Dashboard', icon: <FaTachometerAlt /> },
            { name: 'Profile', icon: <FaUser /> },
            { name: 'Sponsor Income', icon: <FaWallet /> },
            { name: 'Trade Income', icon: <FaChartLine /> },
            { name: 'Affiliate Income', icon: <FaUsers /> },
            { name: 'Wallet', icon: <FaWallet /> },
            { name: 'Withdraw', icon: <FaMoneyCheckAlt /> },
            { name: 'Logout', icon: <FaSignOutAlt /> },
          ].map((tab, index) => (
            <button
              key={index}
              className={`flex items-center space-x-2 p-2 rounded hover:bg-white hover:text-black ${
                activeTab === tab.name ? 'bg-black text-white' : ''
              }`}
              onClick={() => {
                if (tab.name === 'Logout') {
                  handleLogout();
                } else {
                  setActiveTab(tab.name);
                  if (window.innerWidth <= 768) {
                    setIsSidebarOpen(false);
                  }
                }
              }}
            >
              {tab.icon}
              <span>{tab.name}</span>
            </button>
          ))}
        </nav>
      </div>

      <div className="flex-1 py-4 bg-opacity-80">
        <div className="text-2xl font-bold text-white mb-4 ml-4">{activeTab}</div>
        <div>{renderContent()}</div>
      </div>
    </div>
  );
};

export default Profile;
